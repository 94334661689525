import React from "react";
import "./Header.scss";

export class Header extends React.Component {
  render() {
    return (
      <div id="header">
        <a
          href="https://open.spotify.com/track/3swfRaIrfQ77pt2sQOYlXy?si=dawTIB-PQhCq3kcOyPltFg"
          target="_blank"
          rel="noopener noreferrer"
        >
          POPS TUNA REMIX OUT NOW
        </a>
      </div>
    );
  }
}
